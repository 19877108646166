<template>
  <div>
    <page-header :title="title"></page-header>
    <form @submit.prevent="gravar">
      <div class="modal-body">
        <div class="form-horizontal">
          <vue-element-loading
            :active="isActive"
            spinner="bar-fade-scale"
            color="#428BCA"
          ></vue-element-loading>
          <input-form-text
            textInput="Nome"
            :required="true"
            v-model="form.name"
            :value="form.name"
            disabled
          ></input-form-text>
          <input-form-text
            textInput="Telefone"
            :required="true"
            v-model="form.phone"
            :value="form.phone"
            disabled
          ></input-form-text>
          <input-form-text
            textInput="E-mail"
            :required="true"
            v-model="form.mail"
            :value="form.mail"
            disabled
          ></input-form-text>
          <input-form-text
            textInput="Mensagem"
            :required="true"
            v-model="form.message"
            :value="form.message"
            disabled
          ></input-form-text>
        </div>
      </div>
      <footer-button disabled></footer-button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import Permission from '@/mixins/Permission'
import Services from '@/utils/services'
import { erro } from '@/components/Message'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    VueElementLoading
  },
  mixins: [Permission],
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Contato',
      form: {
        name: '',
        phone: '',
        mail: '',
        message: ''
      },
      isActive: false
    }
  },
  methods: {
    ...mapActions(['getLogsTotal']),
    async gravar() {
      let body = { lido: 1 }
      if (this.$route.params.id) {
        await Services.put(`/contato/${this.$route.params.id}`, body).catch(
          response => {
            console.log('error: ' + response)
            erro()
          }
        )
      }
    },
    async getDados() {
      const dados = await Services.get(
        `/contato/${this.$route.params.id}`
      ).catch(response => {
        console.log('error: ' + response)
        return (this.isActive = false)
      })
      this.form.name = dados.name
      this.form.phone = dados.phone
      this.form.mail = dados.mail
      this.form.message = dados.message
      this.isActive = false
    },
    verifPermissao() {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    }
  },
  mounted() {
    this.verifPermissao()
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
      this.gravar()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
